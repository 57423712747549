import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { getShortMonth } from "./event-actions"


const EventForm = ({reg}) => {
  if(reg==='form')
    return (
      <div>
        FORM
      </div>
    )
  else
    return (
      <a className="registration-link" href={reg} target="_blank">Go to Registration</a>
    )
}

const TrainingTemplate = ({data,pageContext}) => {
  console.log('Page Context')
  console.log(pageContext)
  console.log(data)

  const event = data.event
  const daysBetween = Math.round(((new Date(event.frontmatter.endDate)) - new Date(event.frontmatter.startDate))/(1000*60*60*24))+1

  return(
    <Layout>
      <SEO title="Event" />

      <div className="event static-event clearfix">
        <div className="clearfix">
          {/*<h2 className="product"><span className={`icon small product ${data.product.frontmatter.catalog}`}/>{data.product.frontmatter.title}</h2>*/}
          <div className="go-back"><a href={`/events/`}>Go Back</a></div>

        </div>

        <div className="event-info-lock">
          <div className="clearfix">
            <div className="event-stamp" data-gotohref={event.fields.slug}>
              <div className="short-month">{getShortMonth(event.frontmatter.startDate)}</div>
              <div className="date-num">{event.frontmatter.startDate.split('-')[2]}</div>
              <div className="status">{`${daysBetween} Day${daysBetween>1?`s`:``}`}</div>
            </div>
            <div className="event-info">
              <a href={`${event.fields.slug}`} className="event-name">{event.frontmatter.title}</a>
              {/*<p className="event-host">{event.frontmatter.host}</p>*/}
              <p className="event-date">{event.frontmatter.startDate} &ndash; {event.frontmatter.endDate}</p>
              <p className="event-address">{event.frontmatter.address}</p>
            </div>
          </div>

          <div className="event-description">
            <p className="label">Description</p>
            <div dangerouslySetInnerHTML={ {__html: event.html}}/>

            {
              event.frontmatter.website?
                <strong><a href={event.frontmatter.website} target="_blank"><span className="icon link web black"/>More info</a></strong>
                :
                ``
            }
          </div>
        </div>
      </div>

    </Layout>

  )
}



export const query = graphql`
   query ($event: String!) {
      event: markdownRemark(fileAbsolutePath:{regex:$event}){
        fields {
          id
          slug
        }
        frontmatter {
          title
          type
          host
          startDate
          endDate
          address
          website
        }
        html
        excerpt
        }
      }
      
    
  `


export default TrainingTemplate